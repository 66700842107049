<template>&nbsp;</template>

<script>
import axios from 'axios'
// console.log('Keep alive started')

export default {
  name: 'KeepAliveComponent',
  data () {
    return {
      showJWTWarning: false,
      showJWTDanger: false,
      debugMessage: '',
      intervalId: ''
    }
  },
  mounted: async function () {
    // console.log('>>> Keep alive mounted!')
    this.startTimer()
  },
  methods: {
    executeCheck () {
      try {
        axios.get(process.env.VUE_APP_AUTH_SERVER + '/warmup')
        axios.get(process.env.VUE_APP_API_SERVER + '/warmup')
      } catch (e) {
        //
      }
    },
    startTimer () {
      const self = this
      self.executeCheck()
      self.intervalId = setInterval(function () {
        self.executeCheck()
      }, 1000 * 30 * 1)
    }
  }
}
</script>
