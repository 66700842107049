<template>
  <div class="modal modal-sheet position-static d-block bg-body-secondaryQQQ p-4 py-md-5" tabindex="-1" role="dialog" id="modalSignin">
    <div class="modal-dialog" role="document">
      <div class="modal-content rounded-4 shadow dark-or-not">
        <div class="modal-header p-5 pb-4 border-bottom-0">
          <h1 class="fw-bold mb-0 fs-2 dark-or-not-text" id="textLogin">{{ data?.textLogin }}</h1>
        </div>
  
        <div class="modal-body p-5 pt-0">
          <form class="" @submit.prevent="login()">
            <div class="form-floating mb-3">
              <input type="email" class="form-control rounded-3 dark-or-not" id="floatingInput" placeholder="name@example.com" v-model="email">
              <label for="floatingInput" id="labelEmail" class="dark-or-not-text">{{ data?.labelEmail }}</label>
            </div>
            <div class="form-floating mb-3">
              <input type="password" class="form-control rounded-3 dark-or-not" id="floatingPassword" placeholder="Password" v-model="password">
              <label for="floatingPassword" id="labelPassword" class="dark-or-not-text">{{ data?.labelPassword }}</label>
            </div>
            <div class="btn btn-warning incorrect-data">
              {{ data?.incorrectData }}
            </div>
            <button class="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit" id="buttonLogin" @click.prevent="login()">
              <span id="buttonLoginText">{{  data?.buttonLoginText }}</span>
              &nbsp;<span v-if="loadingLogin"><i class="fa fa-circle-o-notch fa-spin"></i></span>
            </button>
            <small class="text-body-secondary" id="labelForgotPassword"></small>
            <br />
            <small class="text-body-secondary" id="labelNotAUser"></small>

            <div style="display: block; margin-top: 40px;" @click="goTo('google')">
              <button class="w-100 py-2 mb-2 btn btn-outline-primary rounded-3" type="submit" @click.prevent="loginGoogle()">
                <i class="fa fa-google-plus"></i> 
                {{ data?.continueWithGoogle }}
                <span v-if="loadingGoogle"><i class="fa fa-circle-o-notch fa-spin"></i></span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="row" v-show="development()">
    <div class="col-6 offset-sm-3 justify-content-evenly">
      Todo list:
      <ul>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Mandar mail avisando que entren a app-v1</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Configurar ambientes</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />[posponer hasta nuevo aviso] hacer que app apunte a nueva aplicación de auth</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Crear proyectos en GCP y revisar los nombres (auth-api-prod.wawentu.com y auth-api-testing.wawentu.com)</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Crear proyectos en cloudflare y revisar los nombres (auth-app-prod.wawentu.com y auth-app-testing.wawentu.com)</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />inicializar warmup de todas las versiones acá</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />IDEA: verificar token, si tiene menos de x horas no hacer nada, pero si tienes más, entonces redirigir</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />verificar si hay que implementar acá lo de la verificación del token</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Esconder mensajes cuando corresponda</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Loading de cada elemento del login</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Loading general de la página</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Mostrar modal en forma condicional</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Mostrar mensaje de redirección o bien resultado con problemas del auth</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Renombrar versiones actuales app-v1 y app-v2</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Una vez que esté bien probado que este sea app (por ahora app-login)</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Sacar logs que no sean necesarios</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Que al cargar esta página, valide el token, y si tiene más de x horas disponibles podría redireccionar, por ejemplo 12 horas (no puede ser muy poco de lo contrario tendrá qeu volver a meter la clave)</li>
        <li><input type="checkbox" checked class="form-check-input checkbox-one" />Modificar form para que el enter sea el submit del login form</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Ver potenciales errores:</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />http://localhost:3089/#error=Cannot%20read%20properties%20of%20undefined%20%28reading%20%27sub%27%29</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />http://localhost:8080/auth?error=Cannot%20read%20properties%20of%20undefined%20%28reading%20%27_id%27%29</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />https://cloud.wawentu.com/auth?error=Grant%3A%20OpenID%20Connect%20nonce%20mismatch</li>
        <li><input type="checkbox" class="form-check-input checkbox-one" />Cuando intento ingresar con un usuario que no existe: https://cloud.wawentu.com/auth?error=Cannot%20read%20properties%20of%20undefined%20%28reading%20%27_id%27%29</li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

let locale = navigator ? navigator.language : 'en'
if (locale.indexOf('-') !== -1) locale = locale.split('-')[0]
// only valid locales
if (['en', 'es'].indexOf(locale) === -1) locale = 'en'
// console.log('>>> Using locale:', locale)

const dataFull = {
  en: {
    textLogin: 'Login',
    labelEmail: 'email',
    labelPassword: 'password',
    labelForgotPassword: 'forgot you password?',
    labelNotAUser: 'not a user?',
    buttonLoginText: 'login',
    incorrectData: 'Your email or password is incorrect. Please try again',
    continueWithGoogle: 'continue with Google'
  },
  es: {
    textLogin: 'Entrar',
    labelEmail: 'email',
    labelPassword: 'clave',
    labelForgotPassword: '¿olvidaste tu clave?',
    labelNotAUser: '¿no eres un usuario?',
    buttonLoginText: 'entrar',
    incorrectData: 'Tu email o clave son incorrectos. Por favor intenta nuevamente',
    continueWithGoogle: 'continuar con Google'
  }
}

export default {
  name: 'UnifiedLoginPage',
  props: { msg: String },
  data() {
    return {
      loadingLogin: false,
      loadingGoogle: false,
      data:  dataFull[locale],
      email: '',
      password: '',
      api: {}
    }
  },
  async mounted() {
    // console.log('>>> mounted (1)', process.env.VUE_APP_AUTH_SERVER)
    this.getTokenAndGo()
  },
  methods: {
    development() {
      return process?.env?.NODE_ENV === 'development'
    },
    getToken() {
      const currentUrl = new URL(window.location.href)
      const jwt = currentUrl.searchParams.get('access_token')
      return jwt
    },
    async getTokenAndGo (jwt) {
      if (!jwt) jwt = this.getToken()
      if (jwt && jwt.length > 0) {
        try {
          const headers = { Authorization: 'Bearer ' + jwt }
          const usersData = await axios.get(process.env.VUE_APP_API_SERVER + '/users-data', { headers })
          const currentCompanyId = usersData.data?.data?.[0].usersData?.customData?.currentCompanyId?.toString()

          const V1_LIST = ['61b958617b37f5000e1f66f6']
          const V3_LIST = []
          let newUrl
          if (V1_LIST.indexOf(currentCompanyId) !== -1) {
            newUrl = `${process.env.VUE_APP_V1_APP}/loginOauth?access_token=${jwt}`
          } else if (V3_LIST.indexOf(currentCompanyId) !== -1) {
            // @todo
          } else { // default to v2
            newUrl = `${process.env.VUE_APP_V2_APP}/loginOauth?access_token=${jwt}`
          }
          // now redirect
          // console.log('>>> redirect 2', newUrl)
          window.location.href = newUrl
        } catch(e) {
          // console.log('.>> fail', e)
        }
      }
    },
    goTo: function (caso = '') {
      const newUrl = `${process.env.VUE_APP_AUTH_SERVER}/oauth/${caso}?redirect=/auth?&version=3` // si envío datos (payload) debo manejarlo en la api (en getRedirect)
      // console.log('>>> redirect 1', process.env.VUE_APP_AUTH_SERVER, newUrl)
      window.location.href = newUrl
    },
    async login() {
      const { email, password } = this
      this.loadingLogin = true
      try {
        const loginResult = await axios.post(process.env.VUE_APP_AUTH_SERVER + '/authentication', { strategy: 'local', email, password })
        // console.log('>>> here', loginResult)
        if (loginResult?.data?.accessToken) {
          const jwt = loginResult.data.accessToken
          this.getTokenAndGo(jwt)
        }
      } catch (e) {
        // console.log('>>> fail 1', e)
      }
    },
    loginGoogle() {
      this.loadingGoogle = true
      // console.log('>>> login with google')
    }
  }
}
</script>

<style scoped>
.btn-primary {
  background-color: #2c7be5;
}
.incorrect-data {
  background-color: #f6c343;
  margin-bottom: 7px;
}
.checkbox-one {
  margin-right: 10px;
}
</style>
