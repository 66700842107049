<template>
  <LoginPage />
  <KeepAliveComponent />
</template>

<script>
import LoginPage from './components/LoginPage.vue'
import KeepAliveComponent from './components/KeepAlive.vue'

export default {
  name: 'App',
  components: {
    LoginPage,
    KeepAliveComponent,
  }
}
</script>

<style>

@media (prefers-color-scheme: dark) {
  .dark-or-not {
    background-color: #1e3a5c !important;
    /* color: white !important; */
    /* color: red !important; */
  }
  .dark-or-not-text {
    /* background-color: #1e3a5c !important; */
    color: white !important;
    /* color: red !important; */
  }
}

/* @import './assets/themes/dashkit/css/theme-dark.bundle.css' screen and (prefers-color-scheme: dark); */
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'assets/vendor/font-awesome-4.7.0/css/font-awesome.min.css';
</style>
